/**
 * Dashkit
 *
 * Custom variables followed by Dashkit variables followed by Bootstrap variables
 * to ensure cascade of styles.
 */

// Bootstrap functions
@import '~bootstrap/scss/functions';

// Custom variables
@import 'user-variables';

// Dashkit variables
@import 'dashkit/variables';

// Bootstrap core
@import '~bootstrap/scss/bootstrap';

// Dashkit core
@import 'dashkit/dashkit';

// Custom core
@import 'user';
