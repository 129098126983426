// 
// jumbotron.scss
// Extended from Bootstrap
//

// 
// Bootstrap Overrides =====================================
//

.jumbotron {
  padding: ($jumbotron-padding / 2);
  @include media-breakpoint-up(sm) {
    padding: $jumbotron-padding;
  }
}