//
// code.scss
// Extended from Bootstrap
//

// 
// Dashkit =================================
//

// Highlight
//
// Hightlight.js overrides

.highlight {
  padding: 0;
}