//
// root.scss
// Extended from Bootstrap
//

html {
  height: 100%;
}

body {
  min-height: 100%;
}
