//
// user.scss
// Use this to write your custom SCSS
//

.turbolinks-progress-bar {
  height: 5px;
  background-color: $primary;
}

@import '~animate.css/animate.css';
@import 'plugins/sweetalert2.scss';
@import 'plugins/flatpickr.scss';
@import 'plugins/select2.scss';